.approachSection {}

.approachGettingInfo {}

.approachGettingInfo p {
    max-width: 1000px;
    margin: auto;
}


.approachListing {
    margin: 0;
    width: 100%;
    padding: 0 15rem;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 25px 40px;
}

.approachListing li {
    margin: 0;
    padding: 0;
    width: calc(20% - 32px);
    position: relative;
}

.approachListing li:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 75px;
    height: 1px;
    background: #969ABC;
}

.approachListing li:after {
    content: '';
    position: absolute;
    right: -30px;
    top: 67px;
    width: 20px;
    height: 20px;
    background: url("../../assets/Images/icon/right_arrow.png") no-repeat 0px 0px;
    background-size: 100%;
}

.approachListing li:last-child::after {
    display: none;
}

.approachListing li:first-child:before {
    left: 50%;
}

.approachListing li:last-child:before {
    right: 50%;
}


.choose-us .checklist {
    padding-left: 0px;
}

.checklist li {
    text-align: left !important;
}
.choose-us .checklist li p{
    padding-left: 30px !important;
}

.choose-us .checklist li:before {
    margin-left: -15px !important;
}
