.font-family {
    font-family: "Source Sans Pro", sans-serif;
    -webkit-font-smoothing: antialiased;
}

.hero-area {
    background-image: url(../Images/slider/slider-bg-01.jpg);
}


.single-page-header::before {
    background-image: url(../Images/slider/call-to-action-bg.jpg);
}

.bg-theme {
    background-color: #5cc1d6;
}

.google-map {
    height: 25rem;
    width: 100%;
}

.checklist {
    padding-left: 30px;
}

.checklist li {
    position: relative;
    /* margin-bottom: 15px; */
    margin: 0rem 1rem 1rem 0.5rem;
    font-size: 16px;
    color: #777;
    font-family: "Source Sans Pro", sans-serif;
}

.checklist li:before {
    position: absolute;
    margin-right: 12px;
    margin-left: -45px;
    content: "\f375";
    font-family: "themefisher-font";
    opacity: 1;
    border-radius: 50%;
    padding: 2px 8px;
    font-size: 20px;
}


/* 
.checklist li p:before {
    position: absolute;
    margin-right: 12px;
    margin-left: -45px;
    content: "\f375";
    font-family: "themefisher-font";
    opacity: 1;
    border-radius: 50%;
    padding: 2px 8px;
    font-size: 20px;
} */
.justify-text {
    text-align: justify;
    text-justify: inter-word;
}

.footer-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
.nav-link{
    cursor: pointer;
}