.process-flow {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
}

.process-flow li {
    position: relative;
    flex: 1;
    text-align: center;
}

.process-flow li:first-child .approachListBox::before {
    border: none;
}

.process-flow li .approachListBox::before {
    content: "";
    position: absolute;
    top: 5%;
    right: 7rem;
    /* transform: translateY(77%); */
    width: 75%;
    height: 100%;
    background-image: url(../../assets/Images/icon/flow-arrow.png);
    background-repeat: no-repeat;
    transform: scale(0.8);
}

.process-flow li:first-child .approachListBox::before {
    display: none;
}

.process-flow li span {
    display: block;
    margin-top: 10px;
}

.approachListBox {
    display: flex;
    flex-direction: column;
    gap: 25px 0;
    text-align: center;
}

.approachListBox .image {
    width: 90px;
    height: 90px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin: auto;
    box-shadow: 0px 20px 35px rgb(0 0 0 / 7%)
}

.home-section .approachListBox .image {
    width: 110px;
    height: 110px;
}

.approachListBox .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.approachListBox .overlayTitle {
    position: absolute;
    left: 0;
    right: 0;
    top: 10px;
    bottom: 0;
    font-size: 55px;
    font-weight: 500;
    line-height: 1;
    color: var(--white);
    font-family: var(--font2);
    background: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    border-radius: 50%;
}

.approachListBox .overlayTitle span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    background: var(--primary);
    margin: 10px -10px 0 8px;
}

.industry_icons img {
    width: 10rem;
}

@media(max-width:1440px) {
    .process-flow li .approachListBox::before {
        background-image: url(../../assets/Images/icon/right_arrow.png);
        right: 5rem;
    }
}

@media(max-width:1000px) {
    .process-flow {
        display: grid;
        justify-content: center;
    }

    .process-flow li .approachListBox::before {
        display: none;
    }
}