.get-in-touch a {
    padding: 1rem !important;
}

.navigation .navbar-light .navbar-nav .get-in-touch.active .btn-outline-light {
    color: #28ABE3;
    border-color: #28ABE3;

}


.get-in-touch.active .nav-link {}

.sticky-header .get-in-touch .btn-outline-light {
    color: #464646;
    border-color: #464646;
}