.sticky-top {
    top: 85px !important;
}

.services {
    padding: 2rem 0rem;
}

.section {
    /* padding: 1rem 1rem; */
}

@media(max-width:1024px) {
    .sub-service .navbar-dark {
        display: none;
    }
}

.sub-service .nav-link {
    text-align: center;
}

.sub-service .section.active {
    padding-top: 10rem;
}

.sub-service .active.nav-item {
    background: slategray;
    border-radius: 4rem;
    padding: 0rem 1rem;
}

.working-benefits .service-item {
    display: grid;
    align-content: space-between;
}

.working-benefits img {
    width: 4rem;
    /* height: 6rem; */
    padding: 0.5rem;
    border-radius: 15%;
    margin: auto;
    /* background: #ebebeb; */
}

.service-item.active {
    -webkit-box-shadow: 0 5px 65px 0 rgba(0, 0, 0, 0.15);
}

.sub-service .process-flow img {
    padding: 1.5rem;
    background: #91deff6b;
}

.sub-service .service-block-1 .about-2-item {
    padding-left: 3rem;
}

@media(max-width:767px) {

    /* .sub-service .navbar-dark {
        display: none;
    } */
    .sub-service .service-block-1 .row {
        flex-direction: column-reverse !important;
    }
}

.sub-service .working-benefits .industry_icons {
    height: 4rem;
    width: 5rem;
    border-radius: 15%;
}