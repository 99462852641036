.expertise_line_1 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.expertise_line {
    margin-top: 2rem;
}

.service-logo {
    width: 15rem !important;
    height: 5rem;
    background: #f5f5f5;
    border-radius: 0.5rem;
    margin: 1rem 2rem;
    padding: 0.5rem;
}

.bg-gray .service-logo {
    background: #fff;
}

/* both side arrow for next and pri  */
.slick-arrow {
    display: none !important;
}

.slick-dots {
    display: flex !important;
}

.mobile-services {
    display: none;
}

@media(max-width:767px) {
    .expertise_line_1 img {
        width: 95px;
        height: 95px;
    }

}

@media(max-width:575px) {
    .expertise_line_1 img {
        width: 4.5rem;
        height: 2.7rem;
        margin: 0.2rem 0.2rem;
        padding: 0.2rem;
        background: white;
        border-radius: 0.5rem;
    }
}

@media(max-width:1440px) {
    .mobile-services {
        display: block;
    }

    .web-services {
        display: none;
    }
}

@media(max-width:992px) {
    .service-logo {
        width: 10rem !important;
        height: 4rem;
    }
}

@media(max-width:550px) {
    .service-logo {
        width: 8rem !important;
        height: 4rem;
    }
}

@media(max-width:450px) {
    .service-logo {
        width: 5rem !important;
        height: 2rem;
    }
}

@media(max-width:350px) {
    .service-logo {
        width: 4rem !important;
        height: 2rem;
    }
}