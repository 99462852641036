.card:hover {
    box-shadow: 0 5px 65px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 5px 65px 0 rgba(0, 0, 0, 0.15);
}

.card {
    border: 1px solid #eee;
    margin-bottom: 30px;
    /* padding: 15px 15px; */
    transition: all 0.3s ease 0s;
}

.post-content {
    color: #393939 !important;
}

.blog-title {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    /* height: 1.2em; */
    white-space: nowrap;
}

.blog-img {
    height: 15rem;
    width: 100%;
}


.post-content {
    color: #646464 !important;

    ul li,
    p {
        color: #646464 !important;
        margin-bottom: 0.5rem;
    }
}

.post-content ul {
    margin-bottom: 20px;
}

.post-content ul {
    margin-bottom: 20px;
    /* margin-left: 2rem; */
}

.post-content .checklist {
    padding-left: 30px;
}

