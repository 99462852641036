.cf-submit {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}



.modal-dialog {
    min-width: 40rem;

}

/* input[type="file"] {
    display: none;
  }
  
  .custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
  } */


.post-content {
    color: #646464 !important;

    ul li,
    p {
        color: #646464 !important;
        margin-bottom: 0.5rem;
    }
}

.opening ul {
    margin-bottom: 20px;
}

.opening .checklist {
    padding-left: 30px;
}

.checklist li {
    /* margin: 0rem 1rem 0.5rem 0.5rem !important; */
}