
.vision-mission-img {
    width: 5rem;
    border-radius: 10rem;
    margin-bottom: 1rem;
}

.bg-blue {
    background: #28abe3;
}

.checklist li:before {
    margin-left: -45px !important;
}

@media(max-width:955px) {

    .industry_icons {
        height: 10rem;
        width: 10rem;
    }

    .industry_icons img {

        width: 5rem;
    }

    .industry_service_bottom {
        margin-top: 3rem;
    }
}

@media(max-width:430px) {
    .checklist li:before {
        margin-left: -40px !important;
    }
}